import { differenceInSeconds, format, getMinutes } from 'date-fns';
import { ja as jaLocale } from 'date-fns/locale';

const JAPANESE_DATE_FORMAT = 'yyyy年M月d日（EEEEE）';
const JAPANESE_DATE_FORMAT_WITHOUT_WEEK = 'yyyy年M月d日';
const JAPANESE_YEAR_FORMAT = 'yyyy年';

export const useFormattedDateTime = (
  date: RefOrGetterFunction<Date | null>
) => {
  return computed(() => {
    const v = toValue(date);
    return v != null ? formatDateTime(v) : '';
  });
};
const formatDateTime = (date: Date): string =>
  format(date, 'yyyy/M/d H:mm', { locale: jaLocale });

export const useFormattedDiffDateTime = (date: RefOrGetterFunction<Date>) => {
  return computed(() => formatDiffDateTime(toValue(date)));
};

export const formatDiffDateTime = (date: Date): string => {
  const diff = differenceInSeconds(new Date(), date);
  if (diff < 60) {
    return '今';
  } else if (diff < 60 * 60) {
    return `${Math.floor(diff / 60)}分前`;
  } else if (diff < 60 * 60 * 24) {
    return `${Math.floor(diff / (60 * 60))}時間前`;
  } else if (diff < 60 * 60 * 24 * 30) {
    return `${Math.floor(diff / (60 * 60 * 24))}日前`;
  } else {
    return format(date, 'M月d日', { locale: jaLocale });
  }
};

export const useFormattedJapaneseDate = (date: RefOrGetterFunction<Date>) => {
  return computed(() => formatJapaneseDate(toValue(date)));
};
const formatJapaneseDate = (date: Date): string =>
  format(date, JAPANESE_DATE_FORMAT, { locale: jaLocale });

export const useFormattedJapaneseDateWithoutWeek = (
  date: RefOrGetterFunction<Date>
) => {
  return computed(() => formatJapaneseDateWithoutWeek(toValue(date)));
};
export const formatJapaneseDateWithoutWeek = (date: Date): string =>
  format(date, JAPANESE_DATE_FORMAT_WITHOUT_WEEK, { locale: jaLocale });

export const useFormattedJapaneseYear = (date: RefOrGetterFunction<Date>) => {
  return computed(() => formatJapaneseYear(toValue(date)));
};
const formatJapaneseYear = (date: Date): string =>
  format(date, JAPANESE_YEAR_FORMAT, { locale: jaLocale });

export const useFormattedJapaneseTime = (date: RefOrGetterFunction<Date>) => {
  return computed(() => formatJapaneseTime(toValue(date)));
};
const formatJapaneseTime = (date: Date): string => {
  const timeFormat = getMinutes(date) === 0 ? 'H時' : 'H時m分';
  return format(date, timeFormat, { locale: jaLocale });
};
